// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

console.log(process.env)

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDCZEcSU7nH4ZcZk8SPOyKT8Vy49I8Zz-4",
  authDomain: "indoorgraphs-72f11.firebaseapp.com",
  projectId: "indoorgraphs-72f11",
  storageBucket: "indoorgraphs-72f11.appspot.com",
  messagingSenderId: "1081053113579",
  appId: "1:1081053113579:web:b963f6560234f90ce322ae"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig)

export  const db = getFirestore(app);
